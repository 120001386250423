let gtmScriptAdded = false;

declare global {
  interface Window {
    [key: string]: object[];
  }
}

export const GTM_ID = 'GTM-NPNC33Z4';

export function addGtmScript() {
  if (!GTM_ID || gtmScriptAdded) {
    return;
  }

  (function (w: Window, d: Document, s: 'script', l: string, i: string) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement<'script'>(s);
    const dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode?.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', GTM_ID);

  gtmScriptAdded = true;
}
